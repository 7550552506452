import React from "react"
import PageTitle from "../components/PageTitle"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import siteCardImage from "../../static/card.jpg"
import { GatsbyImage } from "gatsby-plugin-image"

const getSiteMeta = graphql`
  query {
    site {
      siteMetadata {
        social {
          twitter
          linkedIn
          behance
        }
      }
    }
    file(name: { eq: "personal_photo" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90, placeholder: BLURRED)
      }
    }
  }
`

const strongStyle = {
  fontWeight: "700",
}

const About = () => {
  const { site, file } = useStaticQuery(getSiteMeta)
  const shareCardGenerel = {
    src: siteCardImage,
    width: 1200,
    height: 628,
  }

  const { social } = site.siteMetadata

  const coverImage = file.childImageSharp.gatsbyImageData

  return (
    <>
      <Seo title="عنّي" image={shareCardGenerel} url="/about/" />
      <PageTitle title="عنّي" />

      <div className="columns is-centered">
        <div className="column is-three-quarters-desktop">
          <div className="columns">
            <GatsbyImage
              className="personal-photo column is-half"
              image={coverImage}
              loading="eager"
              fadeIn={false}
              alt="Bandar Abdullah"
            />
            <div className="about-body-wrapper column is-half">
              <p className="about-t1">
                أهلاً بك.. اسمي بندر&nbsp;
                <span role="img" aria-label="أهلاً">
                  🖐🏼
                </span>
              </p>
              <p>
                مصمم متعدد التخصصات، أعمل في مجال تجربة المستخدم&nbsp;
                <span style={strongStyle}>(UX/UI)</span>&nbsp;وتطوير واجهات
                الويب&nbsp;
                <span style={strongStyle}>(FrontEnd Dev)</span>، كما أنني مصمم
                علامات تجارية، مصمم تحريري&nbsp;
                <span style={strongStyle}>(Editorial Designer)</span> وكاتب
                محتوى عربي <span style={strongStyle}>(Copywriter)</span>، ومدير
                مشاريع حاصل على شهادة <span style={strongStyle}>PMP</span>.
                <br />
                من خلال العمل في مشاريع متعددة لفترة تزيد عن الخمسة عشر عامًا،
                استطعت تكوين قاعدة معرفية ومهارية تساعدني على خلق تكامل مع فرق
                العمل التي عملت معها، وتقديم استشارات متنوعة للعملاء في مجالات
                التصميم ومجال تطوير الويب وتطبيقات الهواتف الذكية.
                <br />
                عملت على مشاريع لجهات معروفة مثل:
                <br />
                <span style={strongStyle}>
                  أرامكو - إثراء - وزارة الموارد البشرية والتنمية الاجتماعية -
                  مصرف الراجحي - غرفة الشرقية - المجدوعي - أرز الشعلان - أنديفور
                  السعودية.
                </span>
              </p>
            </div>
          </div>

          <div className="columns">
            <div className="about-body-wrapper column">
              <p className="about-t1 margin-t-0">مهتم بـ</p>
              <p>
                تطوير الذات المستمر، ولهذا أقرأ بشكل يومي في كل ما من شأنه أن
                يضيف لي معرفيًا أو فكريًا، ومهما كان يومي مزدحمًا فسأمنح نفسي
                -على الأقل- خمس دقائق للقراءة، وهي كافية لتحقيق الأثر التراكمي
                الذي أتطلع له على المدى الطويل.
              </p>

              <p className="about-t1">أسلوب الحياة والعمل..</p>
              <p>
                تحليلي، أؤمن بأن التفاصيل الصغيرة تصنع فارقًا كبيرًا، وسواء كان
                القرار المُتخذ للحياة أو العمل لا فرق في هذا، فلا بد من جمع
                معلومات كافية، بالبحث أو السؤال، وربما لهذا السبب وجدت نفسي
                سعيدًا في مجال خبرة المستخدم وبحوثها.
              </p>

              <p className="about-t1">تواصل معي:</p>
              <a
                href={social.linkedIn}
                target="_blank"
                rel="noreferrer"
                alt="linkedIn"
              >
                LinkedIn
              </a>
              <br />
              <a
                href={social.behance}
                target="_blank"
                rel="noreferrer"
                alt="behance"
              >
                Behance
              </a>
              <br />
              {/* <a
                href={social.instagram}
                target="_blank"
                rel="noreferrer"
                alt="instagram"
              >
                Instagram
              </a> 
              <br />*/}
              <a
                href={social.twitter}
                target="_blank"
                rel="noreferrer"
                alt="twitter"
              >
                Twitter
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
